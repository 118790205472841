/**
 * 
 */

/**
 * PressReleasesList.
 */

 export class PressReleasesList {

    /**
     * Private variables.
     */

    private container : HTMLElement

    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        var containerClass : string = '.common-panel-pressreleaseslist-wrapper'
        var container : HTMLElement = document.querySelector(containerClass)

        /**
         * 
         */

        if (! container) 
            return

        /**
         * 
         */

        this.container = container

        /**
         *
         */

        this.configureListItems()

    }

    /**
     * configureListItems.
     */

    configureListItems(): void {

        /**
         * 
         */

        const items : NodeListOf<HTMLElement> = document.querySelectorAll('.press-release-item-teaser-wrapper')

        /**
         * 
         */

        console.log(items)

        /** 
         *
         */

        items.forEach((item) => {

            /**
             *
             */

            item.addEventListener('mouseenter', () => {
                
                /**
                 *
                 */

                const obj : any = item.querySelector('object')

                /**
                 *
                 */

                if (obj.playAnimation)
                    obj.playAnimation()

            })

        })

    }

}
