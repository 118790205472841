/**
 * 
 */

import React from 'react'
import ReactDOM from 'react-dom'

/**
 * 
 */

import { InsightsPostsApp } from './insights/app'

/**
 * InsightsPosts.
 */

export class InsightsPosts {

    /**
     * start.
     */

    start() {

        /**
         *
         */

        const appContainerClass : string = '#insights-posts-app'
        const appContainer : HTMLElement = document.querySelector(appContainerClass)

        /**
         * 
         */

        if (! appContainer)
            return

        /**
         * 
         */
        
        ReactDOM.render(
            <InsightsPostsApp />,
            document.getElementById('insights-posts-app')
        )

    }

}
