/**
 * 
 */

import Rellax from "rellax"

/**
 * RellaxElements.
 */

export class RellaxElements {

    /**
     * constructor.
     */

    constructor() {

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        const elements : NodeListOf<HTMLElement> = document.querySelectorAll('.rellax')

        /**
         * 
         */

        elements.forEach(el => {

            /**
             * 
             */

            var rellax = new Rellax('.rellax', {
                speed      : 2.5,
                center     : false,
                wrapper    : null,
                round      : true,
                vertical   : true,
                horizontal : false                
            })

        })

    }

}
