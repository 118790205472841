/**
 * 
 */

import Player from '@vimeo/player'

/**
 * HomeVideo.
 */

 export class HomeVideo {

    /**
     * Private variables.
     */

    private container : HTMLElement

    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        var containerClass : string = '.home-panel-video-wrapper'
        var container : HTMLElement = document.querySelector(containerClass)

        /**
         * 
         */

        if (! container) 
            return

        /**
         * 
         */

        this.container = container

        /**
         * 
         */

        this.configureFlatVideos()

    }

    /**
     * configureFlatVideos.
     */

    configureFlatVideos() {

        /**
         * 
         */

        let videos : Array<any> = []

        /**
         * 
         */

        var videoContainers = document.querySelectorAll('.flatvideo-embed-container')

        /**
         * 
         */

        videoContainers.forEach((container : HTMLElement) => {
    
            /**
             * 
             */

            let videoId = container.attributes.getNamedItem('data-video').value

            /**
             * 
             */
    
            const options : any = {
                id        : videoId,
                loop      : true,
                controls  : false,
                muted     : true,
                autopause : false
            }
    
            /**
             * 
             */

            let player = new Player(container, options)
            
            /**
             * 
             */

            let videoConf = {
                player : player,
                id : videoId
            }

            /**
             * 
             */

            let playButton : HTMLElement = container.parentElement.querySelector('.video-player-trigger')

            /**
             * 
             */

            playButton.addEventListener('click', (e) => {

                /**
                 * 
                 */

                player.play()

                /**
                 * 
                 */

                container.parentElement.classList.add('video-playing')

                /**
                 * 
                 */

                e.preventDefault()                

            })

            /**
             * 
             */

            videos.push(videoConf)

        })

    }

}
