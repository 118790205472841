/**
 * 
 */

import React, { SyntheticEvent } from 'react'
import { SVGInjector } from '@tanem/svg-injector'

/**
 * 
 */

interface IPaginatorProps {
    totalItemsCount : number;
    visibleItemsCount : number;
    itemsGroupSize : number;
    showMore   : (e : SyntheticEvent) => void;
}

/**
 * 
 */

export class Paginator extends React.Component<IPaginatorProps, {}> {

    /**
     * 
     */

    constructor(props : IPaginatorProps) {
        super(props)
    }

    /**
     * 
     */

    componentDidMount() {

        /**
         * 
         */

        SVGInjector(document.querySelectorAll('[data-svg-replace]'), {
            cacheRequests: false,
            evalScripts: 'once',
            renumerateIRIElements: false,
        })

    }

    /**
     * 
     */

    getListitemClasses(item : string) {

        /**
         * 
         */

        let classes : Array<string> = []

        /**
         * 
         */

        if (item == 'next') {

            if (this.props.visibleItemsCount == this.props.totalItemsCount) {
                classes.push('disable')
            }

            if (this.props.totalItemsCount < this.props.visibleItemsCount) {
                classes.push('disable')
            }

        }

        /**
         * 
         */

        return classes.join(' ')
    }

    /**
     * 
     */

    render() {

        /**
         * 
         */

        return (
            <ul className="paginator">
                <li>
                    <a href="#" className={this.getListitemClasses('next')} onClick={this.props.showMore}><span>Load more</span></a>
                </li>
            </ul>
        )

    }

}
