/**
 * 
 */

/**
 * Header.
 */

export class Header {

    /**
     * constructor.
     */

    constructor() {

    }

    /**
     * start.
     */

    start(): void {

        /**
         *
         */

        let contactMenuItem : HTMLElement = document.querySelector('.col-menu .main-menu a[href="#"]')

        /**
         *
         */

        const _window : any = window

        /**
         *
         */

        contactMenuItem.setAttribute('href', 'mailto:' + _window.contactEmailAddress + '?subject=' + _window.contactEmailSubject)

    }

}
