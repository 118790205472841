/**
 * 
 */

import simpleParallax from 'simple-parallax-js'

/**
 * SimpleParallax.
 */

export class SimpleParallax {

    /**
     * constructor.
     */

    constructor() {

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        // let elements : NodeListOf<HTMLElement> = document.querySelectorAll('.simple-parallax')
        // let elementsArray : Array<HTMLElement> = []
        // elements.forEach(el => elementsArray.push(el))

        /**
         * 
         */

        var instance = new simpleParallax(
            document.querySelector('.simple-parallax'),
            {
                overflow: true
            }
        )

        /**
         * 
         */

        instance.refresh()

    }

}
