/**
 * 
 */

import { IMooseWindow } from '../effects/lottie-animations'

/**
 * Splash.
 */

export class Splash {

    /**
     * constructor.
     */

    constructor() {

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        const splashWrapper : HTMLElement = document.querySelector('.splash-wrapper')
        const lottiePlayer : HTMLElement = document.querySelector('.splash-wrapper lottie-player')

        /**
         * 
         */

        if (! splashWrapper) {
            return
        }

        /**
         * 
         */

        setTimeout(() => {
            splashWrapper.classList.add('showing-shape')
        }, 50)

        /**
         * 
         */

        setTimeout(() => {

            /**
             *
             */

            splashWrapper.classList.add('showing-logo')
            lottiePlayer.classList.add('showing')

            /**
             * 
             */

            const mWindow : IMooseWindow = window
            mWindow.mooseSplashAnim.play()

            /**
             *
             */

        }, 100)

        /**
         * 
         */

        setTimeout(() => {
            splashWrapper.classList.add('hiding')
        }, 2800)

    }

}
