/**
 * 
 */

import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import Swiper, { Autoplay } from 'swiper'
Swiper.use([Autoplay])

/**
 * HomeOwnershipModels.
 */

export class HomeOwnershipModels {

    /**
     * Private variables.
     */

    private swiperInstance : Swiper

    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        let swiperClass : string = '.swiper-container'

        /**
         * 
         */

        var panelClass : string = '.home-panel-ownershipmodels-container'
        var containers : NodeListOf<HTMLElement> = document.querySelectorAll(panelClass)
        var container : HTMLElement

        /**
         * 
         */

        if (containers.length > 0) {

            /**
             * 
             */

            container = containers.item(0)

        }

        /**
         * 
         */

        if (! container) {
            return
        }

        /**
         * 
         */

        // let currentPageElement : HTMLElement = container.querySelector('.current')
        // let totalPageElement : HTMLElement   = container.querySelector('.total')
        // let nextElement : HTMLElement        = container.querySelector('.next')
        // let prevElement : HTMLElement        = container.querySelector('.prev')

        let slideSelectorList : HTMLElement  = container.querySelector('.slide-selector-list')
        let slideSelectorListItems : NodeListOf<HTMLElement> = slideSelectorList.querySelectorAll('li a')

        let iconList : HTMLElement           = container.querySelector('.icon-list')
        let iconListItems : NodeListOf<HTMLElement> = iconList.querySelectorAll('li')

        let dotsSelectorList : HTMLElement  = container.querySelector('.dots-selector-list')
        let dotsSelectorListItems : NodeListOf<HTMLElement> = dotsSelectorList.querySelectorAll('li a')

        /**
         * 
         */         

        this.swiperInstance = new Swiper(swiperClass, {
            autoHeight: true,
            loop: false,
            autoplay: {
                delay: 4000,
                disableOnInteraction: true,
            },
            on: {

                /**
                 * init.
                 */

                afterInit: () => {

                    /**
                     * 
                     */

                    iconListItems.item(1).classList.add('hide')

                },

                /**
                 * 
                 */

                slideChange: () => {

                    /**
                     * 
                     */

                    if (! this.swiperInstance)
                        return 

                    /**
                     * 
                     */

                    // currentPageElement.innerHTML = (this.swiperInstance.activeIndex + 1).toString() 

                    /**
                     * 
                     */

                    slideSelectorListItems.forEach((item, index) => {

                        /**
                         * 
                         */

                        item.parentElement.classList.remove('active')
                        if (index == this.swiperInstance.activeIndex)
                            item.parentElement.classList.add('active')

                    })

                    /**
                     * 
                     */

                    dotsSelectorListItems.forEach((item, index) => {

                        /**
                         * 
                         */

                        item.parentElement.classList.remove('active')
                        if (index == this.swiperInstance.activeIndex)
                            item.parentElement.classList.add('active')

                    })
                    
                    /**
                     * 
                     */

                    iconListItems.forEach((item, index) => {

                        /**
                         * 
                         */

                        item.classList.add('hide')
                        if (index == this.swiperInstance.activeIndex)
                            item.classList.remove('hide')

                    })

                }               

            }
        })

        // /**
        //  * Add event listener to next element.
        //  */

        // nextElement.addEventListener('click', (e) => {
           
        //     /**
        //      * 
        //      */

        //     this.swiperInstance.slideNext()

        //     /**
        //      * 
        //      */

        //     e.preventDefault()

        // })

        // /**
        //  * Add event listener to prev element.
        //  */

        // prevElement.addEventListener('click', (e) => {
           
        //     /**
        //      * 
        //      */

        //     this.swiperInstance.slidePrev()

        //     /**
        //      * 
        //      */

        //     e.preventDefault()

        // })       

        /**
         * 
         */

        slideSelectorListItems.item(0).parentElement.classList.add('active')

        /**
         * 
         */

        slideSelectorListItems.forEach((item, index) => {

            /**
             * 
             */

            item.addEventListener('click', (e) => {

                /**
                 * 
                 */

                this.swiperInstance.slideTo(index)

                /**
                 * 
                 */

                e.preventDefault()

            })

        })

        /**
         * 
         */

        dotsSelectorListItems.item(0).parentElement.classList.add('active')

        /**
         * 
         */

        dotsSelectorListItems.forEach((item, index) => {

            /**
             * 
             */

            item.addEventListener('click', (e) => {

                /**
                 * 
                 */

                this.swiperInstance.slideTo(index)

                /**
                 * 
                 */

                e.preventDefault()

            })

        })

        /**
         * 
         */

        // totalPageElement.innerHTML = (slideSelectorListItems.length).toString()

    }

}
