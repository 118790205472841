/**
 * Utils.
 */

import { Ready } from './utils/ready'

/**
 * Layouts.
 */

import { Header } from './layouts/common/header'

/**
 * Panels.
 */

import { HomeVideo } from './panels/homevideo'
import { HomeOwnershipModels } from './panels/homeownershipmodels'
import { PressReleasesList } from './panels/pressreleaseslist'

/**
 * Effects.
 */

import { SVGReplace } from './effects/svg-replace'
import { ScrollsIntoView } from './effects/scolls-into-view'
import { SimpleParallax } from './effects/simple-parallax'
import { RellaxElements } from './effects/rellax-elements'
import { AnimatedIcons } from './effects/animated-icons'
import { LottieAnimations } from './effects/lottie-animations'

/**
 * Components.
 */

import { Splash } from './components/splash'

/**
 * React
 */

import { InsightsPosts } from './react/insights-posts'

/**
 * App.
 */

export class App {

    /**
     * Layouts.
     */

    private header : Header

    /**
     * Panels.
     */

    private homeVideo : HomeVideo
    private HomeOwnershipModels : HomeOwnershipModels
    private pressReleasesList: PressReleasesList

    /**
     * Effects.
     */

    private svgReplace : SVGReplace
    private scrollsIntoView : ScrollsIntoView
    private simpleParallax : SimpleParallax
    private rellaxElements : RellaxElements
    private animatedIcons : AnimatedIcons
    private lottieAnimations : LottieAnimations

    /**
     * Components.
     */

    private splash : Splash

    /**
    * React.
    */

    private insightsPosts : InsightsPosts

    /**
     * constructor.
     */

    constructor() {

        /**
         * Layouts.
         */

        this.header = new Header()

        /**
         * Panels.
         */       

        this.homeVideo = new HomeVideo()
        this.HomeOwnershipModels = new HomeOwnershipModels()
        this.pressReleasesList = new PressReleasesList()

        /**
         * Effects.
         */

        this.svgReplace = new SVGReplace()
        this.scrollsIntoView = new ScrollsIntoView()
        this.simpleParallax = new SimpleParallax()
        this.rellaxElements = new RellaxElements()
        this.animatedIcons = new AnimatedIcons()
        this.lottieAnimations = new LottieAnimations()

        /**
         * Components.
         */

        this.splash = new Splash()

        /**
         * React.
         */

        this.insightsPosts = new InsightsPosts()

    }

    /**
     * start.
     */

    start(): void {

        /**
         * When document is ready.
         */

        new Ready(() => {

            /**
             * Start layouts.
             */

            this.header.start()

            /**
             * Start panels.
             */

            this.homeVideo.start()
            this.HomeOwnershipModels.start()
            this.pressReleasesList.start()

            /**
             * Start effects.
             */

            this.svgReplace.start()
            this.scrollsIntoView.start()
            this.simpleParallax.start()
            this.rellaxElements.start()
            this.animatedIcons.start()
            this.lottieAnimations.start()

            /**
             * Components.
             */

            this.splash.start()

            /**
             * Insights.
             */

            this.insightsPosts.start()

        })

    }

}
