/**
 * Import App.
 */

 import { App } from './app'

 /**
  * Instantiate and run.
  */
 
 const app = new App()
 app.start()
 