/**
 * 
 */

import lozad from 'lozad'

/**
 * ScrollsIntoView.
 */

export class ScrollsIntoView {

    /**
     * constructor.
     */

    constructor() {

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        setTimeout(() => {

            /**
             *    
             */

            this.configureScrollsIntoView()

        }, 200)

    }

    /**
     * 
     */

    configureScrollsIntoView() {

        /**
         *
         */

        var observer = lozad('.scrolls-into-view', {

            /**
             *
             */

            threshold: 0.1,

            /**
             *
             */

            enableAutoReload: true,

            /**
             *
             */

            load: function(el : HTMLElement) {

                /**
                 *
                 */
                
                el.classList.add('entered-view')

            }

        })

        /**
         *
         */

        observer.observe()

    }

}
