/**
 * 
 */

import React, { useState } from 'react'
import { SVGInjector } from '@tanem/svg-injector'

/**
 * 
 */

export interface IInsightPostProps {
    key : string;
    title : string;
    teaser : string;
    thumbnail : string;
    date : string;
    permalink : string;
    type: string;
    friendlytype: string;
    isPrimaryPost: boolean;
}

/**
 * 
 */

interface IInsightPostAniElementProps extends IInsightPostProps{
    isVisible : boolean;
}

/**
 * 
 */

interface IInsightPostState {
    isVisible : boolean;
}

/**
 * 
 */

export default class InsightPost extends React.Component<IInsightPostProps, {}> {

    /**
     * 
     */

     componentDidMount() {

        /**
         * 
         */

        SVGInjector(document.querySelectorAll('[data-svg-replace]'), {
            cacheRequests: false,
            evalScripts: 'once',
            renumerateIRIElements: false,
        })

    }

    /**
     * 
     */

    render() {
        return (
            <div className="col-md-4">
                <a href={this.props.permalink} className="insight-teaser-wrapper">
                    <div className="image-container" style={{"backgroundImage": "url(" + this.props.thumbnail + ")"}}>
                        <img className="image" src={this.props.thumbnail} />
                    </div>
                    <ul>
                        <li><p className="date">{this.props.date}</p></li>
                    </ul>
                    <p className="heading">{this.props.title}</p>
                    <div className="teaser">
                        <p>{this.props.teaser}</p>
                    </div>
                    <p className="read-more">Read more</p>
                </a>
            </div>
        )
    }
}
