/**
 * 
 */

import React, { useState, useEffect, SyntheticEvent } from 'react'

/**
 * 
 */

import InsightPost, { IInsightPostProps } from './post';
import { Paginator } from './paginator';

/**
 * 
 */

import scrollIntoView from 'scroll-into-view-if-needed'

/**
 * Interface.
 */

interface InsightPostsAppProps {
    
}

/**
 * Class.
 */

export function InsightsPostsApp() {

    /**
     * 
     */

    const [posts, setPosts] = useState<InsightPost[]>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [error, setError] = useState(null)

    /**
     * 
     */

    const [paginatorTotalItems, setPaginatorTotalItems] = useState<number>(0)
    const paginatorItemsGroupSize = 6
    const [paginatorVisibleItemsCount, setPaginatorVisibleItemsCount] = useState<number>(paginatorItemsGroupSize)

    /**
     * showMore.
     */

    const showMore = (e: SyntheticEvent) => {
        e.preventDefault()
        if (paginatorVisibleItemsCount + paginatorItemsGroupSize > paginatorTotalItems) {
            setPaginatorVisibleItemsCount(paginatorTotalItems)
            return
        }
        setPaginatorVisibleItemsCount(paginatorVisibleItemsCount + paginatorItemsGroupSize)
    }

    /**
     * fetchPosts.
     */

    const fetchPosts = () => {

        /**
         * 
         */

        fetch("/insights-json")
            .then(res => res.json())
            .then(
                (result) => {
                    
                    /**
                     * 
                     */

                    const posts : Array<InsightPost> = result.map((item : IInsightPostProps, index : number) => (
                        <InsightPost 
                            key={index.toString()} 
                            title={item.title}
                            teaser={item.teaser} 
                            thumbnail={item.thumbnail} 
                            date={item.date} 
                            permalink={item.permalink}
                            type={item.type}
                            friendlytype={item.friendlytype}
                            isPrimaryPost={item.isPrimaryPost}
                        />
                    ))
                
                    /**
                     * 
                     */

                    setPosts(posts)
                    setPaginatorTotalItems(result.length)

                    /**
                     * 
                     */

                    setIsLoaded(true);

                },
                (error) => {

                    /**
                     * 
                     */

                    setIsLoaded(true);
                    setError(error);

                }
            )

    }

    /**
     * getVisiblePosts.
     */

    const getVisiblePosts = () => {

        /**
         * 
         */

        let count : number = 0

        /**
         * 
         */

        const visiblePosts = posts.filter((item : InsightPost, index : number) => {

            /**
             * 
             */

            if (count < paginatorVisibleItemsCount) {

                /**
                 * 
                 */

                count++               

                /**
                 * 
                 */

                return true

            }             

            /**
             * 
             */

            return 

        })

        /**
         * 
         */

        return (visiblePosts)

    }

    /**
     * getNoItemsMessage.
     */

    const getNoItemsMessage = () => {

        /**
         * 
         */

        return  (<div className="col-md-12"><p className="message-no-articles">Sorry, we couldn't find any articles.</p></div>)

    }

    /**
     * getLoadingMessage.
     */

    const getLoadingMessage = () => {

        /**
         * 
         */

        return (<div className="col-md-12"><p className="message-loading">Loading...</p></div>)

    }

    /**
     * constructor.
     */

    useEffect(() => {
 
        /**
         * 
         */

        fetchPosts()

    }, [])
  
    /**
     * 
     */

    useEffect(() => {

        /**
         * 
         */

        const paginator : HTMLElement = document.querySelector('.paginator')

        /**
         * 
         */

        if (paginator) {
            scrollIntoView(paginator, {
                scrollMode: 'if-needed',
                block: 'end',
                behavior: actions => {

                    /**
                     * 
                     */

                    actions.forEach(({ el, top, left }) => {
                        window.scroll(0, top + 50)
                    })

                }
            })
        }

    }, [paginatorVisibleItemsCount])


    /**
     * 
     */

    return (
        <>
            { ! isLoaded && getLoadingMessage()}
            { isLoaded && getVisiblePosts().length == 0 && getNoItemsMessage() }
            { isLoaded && getVisiblePosts() }
            { isLoaded && getVisiblePosts().length > 0 && 
                <div className="col-md-12">
                    <Paginator 
                        showMore={showMore}
                        totalItemsCount={paginatorTotalItems} 
                        visibleItemsCount={paginatorVisibleItemsCount}
                        itemsGroupSize={paginatorItemsGroupSize}
                    />
                </div>
            }
        </>
    )

}
